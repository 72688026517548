import { Outlet } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
// @mui
import { Box, Container, Typography, Stack, useTheme } from '@mui/material';
// components
import SocialsButton from '../../components/SocialsButton';
// Header and Footer
// import MainFooter from './MainFooter';
import MainHeader from './MainHeader';
// redux
import { useSelector } from '../../redux/store';



// ----------------------------------------------------------------------

export default function MainLayout() {
   // const urlCompleta = window.location.href;
   // const dominio = `${urlCompleta.split('/')[0]}//${urlCompleta.split('/')[2]}/`;

   const { settings } = useSelector((state) => state.settings);

   // const { pathname } = useLocation();

   // const isHome = pathname === '/';

   const theme = useTheme();

   // const dispatch = useDispatch();

   // useEffect(() => {
   //    dispatch(getSettings());
   // }, [dispatch]);

   return (
      <Stack sx={{ minHeight: 1 }}>
         <MainHeader />

         <Outlet />

         {settings?.certificate?.isCertificated && (
            <Box
               sx={{
                  bgcolor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
                  pb: 5,
                  pt: 1,
                  textAlign: 'center',
                  position: 'relative',
               }}
            >
               <Container sx={{ maxWidth: '800px !important' }}>
                  {/* <LogoCapitalization sx={{ mb: 2, mx: 'auto' }} /> */}

                  <Typography
                     variant="caption"
                     component="span"
                     color={'text.secondary'}
                     sx={{ lineHeight: 1.25, opacity: 0.8 }}
                  >

                     <Typography variant={'body1'} component={'div'}>
                        {ReactHtmlParser(settings?.certificate?.certificateText)}
                     </Typography>

                  </Typography>


               </Container>
            </Box>
         )}

         <Stack
            my={2}
            sx={{
               width: '100%',
            }}
         >
            <SocialsButton initialColor />
         </Stack>

      </Stack>
   );
}
